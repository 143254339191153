import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import UserService from 'services/UserService';
import { RenderIf } from 'components/layout';
import { ChevronLeft } from 'react-feather';
import { Checkbox } from 'components/form';
import { Bold } from 'styles/Commons';
import * as S from '../styles';
import { GENDER, STATE } from 'config/enums';
import AddressService from 'services/AddressService';
import { transformDate } from 'utils/appUtils';
import ReCAPTCHA from "react-google-recaptcha";

enum REGULAR_REGISTER_VIEW {
	USER_VIEW = 'USER_VIEW',
	ACCESS_VIEW = 'ACCESS_VIEW',
	CODE_VIEW = 'CODE_VIEW',
}

const RegularRegister = ({ showBackButton = true, backFn, registerSuccessFn }: any) => {
	const [currentView, setCurrentView] = useState(REGULAR_REGISTER_VIEW.USER_VIEW);
	const [name, setName] = useState({ value: '', hasError: false });
	const [phoneNumber, setPhoneNumber] = useState({ value: '', hasError: false });
	const [userForm, setUserForm] = useState({ invalid: true });

	const stateSelectRef = useRef(null);
	const citySelectRef = useRef(null);
	const [email, setEmail] = useState({ value: '', hasError: false });
	const [password, setPassword] = useState({ value: '', hasError: false });
	const [confirmPassword, setConfirmPassword] = useState({ value: '', hasError: false });
	const [stayConnected, setStayConnected] = useState(false);
	const [accessForm, setAccessForm] = useState({ invalid: true });
	const [isValidatingData, setIsValidatingData] = useState(false);
	const [genderList, setGenderList] = useState<any>([]);
	const [gender, setGender] = useState(null as any);
	const [birthDate, setBirthDate] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [street, setStreet] = useState({ value: '', disabled: false });
	const [number, setNumber] = useState('');
	const [complement, setComplement] = useState('');
	const [neighborhood, setNeighborhood] = useState({ value: '', disabled: false });
	const [state, setState] = useState({ value: '' as any });
	const [city, setCity] = useState({ value: '' as any });
	const [isGettingCities, setIsGettingCities] = useState(false);
	const [stateList, setStateList] = useState<any>([]);
	const [cityList, setCityList] = useState<any>([]);
	const [isStateDisabled, setIsStateDisabled] = useState(false);
	const [isCityDisabled, setIsCityDisabled] = useState(false);
	const [captchaToken, setCaptchaToken] = useState('');
	const captchaRef = useRef(null);

	const [verificationCode, setVerificationCode] = useState({ value: '', hasError: false });
	const [codeForm, setCodeForm] = useState({ invalid: true });
	const [token, setToken] = useState('');
	const [isValidatingCode, setIsValidatingCode] = useState(false);

	const selectStyle = {
		control: (baseStyles: any) => ({
			...baseStyles,
			borderColor: '#E0E0E0',
			borderRadius: '0.5rem',
			minHeight: '44px'
		}),
		menuPortal: (provided: any) => ({
			...provided,
			zIndex: 100
		})
	}

	useEffect(() => {
		getGenderList();
		getStateList();
	}, [])

	useEffect(() => {
		const isFormInvalid = (!name.value || name.hasError || !phoneNumber.value || phoneNumber.hasError);
		setUserForm({invalid: isFormInvalid});
	}, [name, phoneNumber]);

	useEffect(() => {
		const isFormInvalid = (!email.value || email.hasError || !password.value || password.hasError || !confirmPassword.value || confirmPassword.hasError || !captchaToken );
		setAccessForm({invalid: isFormInvalid});
	}, [email, password, confirmPassword, captchaToken]);

	useEffect(() => {
		const isFormInvalid = (!verificationCode.value || verificationCode.hasError);
		setCodeForm({invalid: isFormInvalid});
	}, [verificationCode]);

	const changeName = (ev: any) => {
		ev.persist();
		const value = ev.target.value;
		const nameArray = value.split(' ').filter((name: string) => name.length > 0);

		setName(() => ({
			hasError: value.length === 0 || nameArray.length < 2,
			value: value
		}));
	}

	const changePhoneNumber = (ev: any) => {
		ev.persist();
		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);

		setPhoneNumber(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => ({
			hasError: ev.target.value.length === 0 || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			value: ev.target.value
		}));
	}

	const changePassword = (ev: any) => {
		ev.persist();
		const value = ev.target.value;
		setPassword(() => ({
			hasError: value.length < 6,
			value: value
		}));
		checkPasswords(value);
	}

	const changeConfirmPassword = (ev: any) => {
		ev.persist();
		setConfirmPassword(() => ({
			hasError: ev.target.value.length < 6 || password.value !== ev.target.value,
			value: ev.target.value
		}));
	}

	const changeVerificationCode = (ev: any) => {
		ev.persist();
		setVerificationCode(() => ({
			hasError: ev.target.value.length !== 6,
			value: ev.target.value
		}));
	}

	const checkPasswords = (currentPassword: string) => {
		setConfirmPassword(old => ({
			...old,
			hasError: old.value.length < 6 || currentPassword !== old.value,
		}));
	}

	const goToAccessData = (ev: any) => {
		ev.preventDefault();
		setCurrentView(() => REGULAR_REGISTER_VIEW.ACCESS_VIEW);
	}

	const validateData = (ev: any, isResending = false) => {
		ev.preventDefault();
		setIsValidatingData(true);

		const payload = {
			name: name.value,
			email: email.value,
			password: password.value,
			phoneNumber: phoneNumber.value,
			stayConnected: stayConnected,
			birthDate: birthDate ? transformDate(birthDate) : null,
			gender: gender?.value || null,
			userAddress: {
				zipCode: zipcode ? zipcode.replace(/[^0-9]/g, '') : null,
				street: street.value || null,
				number: number || null,
				complement: complement || null,
				neighborhood: neighborhood.value || null,
				city: city.value || null,
				state: state.value || null,
			}
		}

		UserService.register(payload, captchaToken)
			.then(({ data }) => {
				setToken(() => data);
				setCurrentView(() => REGULAR_REGISTER_VIEW.CODE_VIEW);
				if (isResending) {
					toast('Código reenviado com sucesso.', { type: toast.TYPE.SUCCESS });
				}
			})
			.catch(({ data }) => {
				resetRecaptcha();
				toast(data.message, {type: toast.TYPE.ERROR});
			})
			.finally(() => setIsValidatingData(false))
	}

	const validateCode = (ev: any) => {
		ev.preventDefault();
		setIsValidatingCode(true);
		UserService.validateRegisterCode(token, verificationCode.value)
			.then(({ data }) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				registerSuccessFn('EMAIL');
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsValidatingCode(false))
	}

	const backView = () => {
		if (currentView === REGULAR_REGISTER_VIEW.USER_VIEW) {
			resetRecaptcha();
			backFn();
			return;
		}

		if (currentView === REGULAR_REGISTER_VIEW.ACCESS_VIEW) {
			resetRecaptcha();
			setCurrentView(() => REGULAR_REGISTER_VIEW.USER_VIEW);
			return;
		}

		if (currentView === REGULAR_REGISTER_VIEW.CODE_VIEW) {
			resetRecaptcha();
			setCurrentView(() => REGULAR_REGISTER_VIEW.ACCESS_VIEW);
		}
	}

	const resetRecaptcha = () => {
		setCaptchaToken('');
		//@ts-ignore
		captchaRef.current.reset();
	}

	const getGenderList = () => {
		setGenderList([
			{ label: 'Masculino', value: GENDER.MALE },
			{ label: 'Feminino', value: GENDER.FEMALE },
			{ label: 'Outros', value: GENDER.OTHERS },
			{ label: 'Prefiro não responder', value: GENDER.NO_ANSWER },
		])
	}

	const getStateList = () => {
		setStateList(Object.keys(STATE).map((state: string) => ({ label: state, value: state })))
	}

	const changeZipCode = (ev: any) => {
		ev.persist();
		const value = ev.target.value;

		if (value.length === 9) {
			const zipCode = value.replace(/[^0-9]/g, '');
			getAddressByZipCode(zipCode);
		} else {
			setStreet((old) => ({ ...old, disabled: false }));
			setNeighborhood((old) => ({ ...old, disabled: false }));
			setIsStateDisabled(false);
			setIsCityDisabled(false);
		}

		setZipcode(() => value);
	}

	const getAddressByZipCode = (zipCode: string) => {
		AddressService.getAddressByZipCode(zipCode)
			.then(({ data }) => {
				if (!('erro' in data)) {
					setNeighborhood(() => ({ value: data.bairro, disabled: true }));
					setState({ value: data.uf });
					//@ts-ignore
					stateSelectRef.current.setValue({ label: data.uf, value: data.uf });
					setIsStateDisabled(true);
					setStreet({ value: data.logradouro, disabled: true });

					getUniqueCity(data.uf, data.localidade);
				}
			})
	}

	const getUniqueCity = (state: string, cityName: string) => {
		const params = { state, query: cityName }
		AddressService.getCities(params)
			.then(({ data }) => {
				const option = { label: data.content[0].name, value: data.content[0].id };
				setCityList([option]);
				//@ts-ignore
				citySelectRef.current.setValue(option);
				setIsCityDisabled(true);
			});
	}

	const changeState = (ev: any) => {
		getCities(ev.value);

		setState(() => ({
			value: ev.value,
			disabled: false
		}));
	}

	const searchCities = (inputValue: string) => {
		if (inputValue.length >= 3) {
			getCities(state.value, inputValue);
		}
	}

	const getCities = (state: string, cityName: string = '') => {
		setIsGettingCities(true);
		const params = { state, query: cityName }
		AddressService.getCities(params)
			.then(({ data }) => {
				setCityList(() => data.content.map((city: any) => ({ label: city.name, value: city.id })));
			})
			.finally(() => setIsGettingCities(false))
	}

	return (
		<>
			<S.Header>
				<RenderIf condition={showBackButton || currentView !== REGULAR_REGISTER_VIEW.USER_VIEW}>
					<S.ReturnButton onClick={backView}>
						<ChevronLeft size="1rem" />
					</S.ReturnButton>
				</RenderIf>
				<S.Title>Crie sua conta</S.Title>
			</S.Header>

			<RenderIf condition={currentView === REGULAR_REGISTER_VIEW.USER_VIEW}>
				<form onSubmit={goToAccessData}>
					<S.SmallText>Informe seus dados para criar sua conta</S.SmallText>
					<InputWrapper hasError={name.hasError}>
						<Label data-required>Nome e sobrenome</Label>
						<Input id="name" placeholder="Nome e sobrenome" value={name.value} onChange={changeName} />
						<InputErrorMessage>Insira seu nome e sobrenome</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={phoneNumber.hasError}>
						<Label data-required>Telefone</Label>
						<MaskInput
							id="phoneNumber"
							mask="(99) 99999-9999"
							value={phoneNumber.value}
							maskPlaceholder={null}
							placeholder="(00) 00000-0000"
							onChange={changePhoneNumber} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<Button id="validateMailRegisterData" width="100%" margin="2.25rem 0 0" disabled={userForm.invalid}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === REGULAR_REGISTER_VIEW.ACCESS_VIEW}>
				<form onSubmit={validateData}>
					<InputWrapper hasError={email.hasError}>
						<Label data-required>E-mail</Label>
						<Input id="email" placeholder="seunome@email.com" value={email.value} onChange={changeEmail} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={password.hasError}>
						<Label data-required>Senha</Label>
						<Input id="password" type="password" value={password.value} onChange={changePassword} />
						<InputErrorMessage>Senha deve ter no mínimo 6 caracteres</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={confirmPassword.hasError}>
						<Label data-required>Confirmar senha</Label>
						<Input
							id="confirmPassword"
							type="password"
							value={confirmPassword.value}
							onChange={changeConfirmPassword} />
						<InputErrorMessage>As senhas são diferentes</InputErrorMessage>
					</InputWrapper>

					<InputWrapper>
						<Label>Data de nascimento</Label>
						<MaskInput id="birthDate" mask="99/99/9999" value={birthDate} maskPlaceholder={null} onChange={($event) => setBirthDate($event.target.value)} />
					</InputWrapper>

					<InputWrapper>
						<Label>Gênero</Label>
						<Select
							defaultValue={gender}
							onChange={(option) => setGender(option)}
							options={genderList}
							placeholder=""
							styles={selectStyle}
						/>
					</InputWrapper>

					<InputWrapper>
						<Label>CEP</Label>
						<MaskInput id="zipcode" mask="99999-999" value={zipcode} maskPlaceholder={null} onChange={changeZipCode} />
					</InputWrapper>

					<InputWrapper>
						<Label>Rua</Label>
						<Input id="street" value={street.value} disabled={street.disabled} onChange={($event) => setStreet({ value: $event.target.value, disabled: false })} />
					</InputWrapper>

					<InputWrapper>
						<Label>Número</Label>
						<MaskInput id="number" mask="99999" value={number} maskPlaceholder={null} onChange={($event) => setNumber($event.target.value)} />
					</InputWrapper>

					<InputWrapper>
						<Label>Complemento</Label>
						<Input id="complement" value={complement} onChange={($event) => setComplement($event.target.value)} />
					</InputWrapper>

					<InputWrapper>
						<Label>Bairro</Label>
						<Input id="neighborhood" value={neighborhood.value} disabled={neighborhood.disabled} onChange={($event) => setNeighborhood({ value: $event.target.value, disabled: false })} />
					</InputWrapper>

					<InputWrapper>
						<Label>Estado</Label>
						<Select
							ref={stateSelectRef}
							defaultValue={state.value}
							onChange={changeState}
							options={stateList}
							isDisabled={isStateDisabled}
							noOptionsMessage={() => 'Nenhum estado encontrado'}
							maxMenuHeight={100}
							placeholder=""
							styles={selectStyle}
						/>
					</InputWrapper>

					<InputWrapper>
						<Label>Cidade</Label>
						<Select
							ref={citySelectRef}
							defaultValue={city.value}
							onChange={(option) => setCity({ value: option.value })}
							onInputChange={searchCities}
							options={cityList}
							placeholder=""
							noOptionsMessage={() => 'Nenhuma cidade encontrada'}
							isDisabled={!state.value || isCityDisabled}
							isLoading={isGettingCities}
							loadingMessage={() => 'Buscando cidades'}
							maxMenuHeight={100}
							styles={selectStyle}
						/>
					</InputWrapper>

					<Checkbox id="stayConnected" label="Manter-me conectado" onChangeFn={(isChecked: boolean) => setStayConnected(() => isChecked)} />

					<S.TermsText>Ao me cadastrar, concordo com os <a id="gotoUserTerms" href={`${process.env.REACT_APP_FAQ_URL}/termos-de-uso`} target="_blank">Termos de Uso</a> da plataforma</S.TermsText>

					<ReCAPTCHA
						sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
						ref={captchaRef}
						onChange={($event) => setCaptchaToken($event || '')}
						onExpired={resetRecaptcha}
					/>

					<Button id="validateMailRegisterCredentials" width="100%" margin="2.25rem 0 0" disabled={accessForm.invalid} loading={isValidatingData}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === REGULAR_REGISTER_VIEW.CODE_VIEW}>
				<form onSubmit={validateCode}>
					<S.SmallText>Insira o código de verificação enviado para <Bold>{email.value}</Bold> para verificar sua conta.</S.SmallText>

					<InputWrapper hasError={verificationCode.hasError}>
						<Label data-required>Código de verificação</Label>
						<MaskInput
							id="verificationCode"
							mask="******"
							value={verificationCode.value}
							maskPlaceholder={null}
							placeholder="000000"
							onChange={changeVerificationCode} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<S.SmallText>Não recebeu o código? <S.ResendCode id="MailRegisterResendCode" onClick={($event) => validateData($event, true)}>Reenviar código</S.ResendCode></S.SmallText>

					<Button id="concludeMailRegister" width="100%" margin="2.25rem 0 0" disabled={codeForm.invalid} loading={isValidatingCode}>Criar conta</Button>
				</form>
			</RenderIf>
		</>
	);
}

export default RegularRegister;
