import React, { useState } from 'react';
import { X } from 'react-feather';

import { Modal, RenderIf } from 'components/layout';
import { Button, ButtonOutline } from 'styles/Form';
import * as S from './styles';
import { Login, PasswordRecovery, PhoneAuth, RegularRegister, PhoneRegister } from 'components/standalone';
import SocialAuthButton from 'components/standalone/auth/SocialAuthButton/SocialAuthButton';
import AnalyticsService from 'services/AnalyticsService';

enum AUTH_VIEW {
	AUTH_SELECTION_VIEW = 'AUTH_SELECTION_VIEW',
	LOGIN_VIEW = 'LOGIN_VIEW',
	PASSWORD_RECOVERY_VIEW = 'PASSWORD_RECOVERY_VIEW',
	REGISTER_VIEW = 'REGISTER_VIEW',
}

interface ModalAuth {
	hasCloseOption?: boolean;
	needAuth: boolean;
	isModalOpen: boolean;
	closeModalFn: Function;
}

const ModalAuth = ({hasCloseOption = false, needAuth = false, isModalOpen = false, closeModalFn}: ModalAuth) => {
	const [currentView, setCurrentView] = useState(AUTH_VIEW.AUTH_SELECTION_VIEW);
	const [authType, setAuthType] = useState(process.env.REACT_APP_AUTH_TYPE);

	const closeModal = () => {
		closeModalFn();
		setCurrentView(() => AUTH_VIEW.AUTH_SELECTION_VIEW);
	}

	const showRegisterForm = () => {
		setCurrentView(() => AUTH_VIEW.REGISTER_VIEW);
	}

	const showLoginForm = () => {
		setCurrentView(() => AUTH_VIEW.LOGIN_VIEW);
	}

	const showRecoveryPasswordForm = () => {
		setCurrentView(() => AUTH_VIEW.PASSWORD_RECOVERY_VIEW);
	}

	const emitLoginAnalytics = (type: string) => {
		AnalyticsService.sendLoginEvent(type);
	}

	return (
		<Modal isOpen={needAuth && isModalOpen} showHeader={false} maxWidth="400px" onClose={closeModal}>
			<RenderIf condition={hasCloseOption}>
				<S.CloseButtonWrapper>
					<S.CloseButton onClick={closeModal}>
						<X size="1rem" />
					</S.CloseButton>
				</S.CloseButtonWrapper>
			</RenderIf>

			<RenderIf condition={currentView === AUTH_VIEW.AUTH_SELECTION_VIEW}>
				<S.SelectLoginText>Para continuar a compra, acesse sua conta.</S.SelectLoginText>

				<RenderIf condition={process.env.REACT_APP_HAS_SOCIAL_AUTH === 'true'}>
					<SocialAuthButton type="FACEBOOK" authSuccessFn={emitLoginAnalytics} />

					<SocialAuthButton type="GOOGLE" authSuccessFn={emitLoginAnalytics} />

					<S.ButtonsSeparator>ou</S.ButtonsSeparator>
				</RenderIf>

				<Button width="100%" margin="0 0 0.75rem" onClick={showRegisterForm}>Criar conta</Button>

				<ButtonOutline width="100%" onClick={showLoginForm}>Já tenho conta</ButtonOutline>
			</RenderIf>

			<RenderIf condition={currentView === AUTH_VIEW.LOGIN_VIEW}>
				<RenderIf condition={authType === 'EMAIL'}>
					<Login
						recoveryPasswordFn={showRecoveryPasswordForm}
						loginSuccessFn={emitLoginAnalytics}
						changeTypeFn={() => setAuthType('PHONE')}
						backFn={() => setCurrentView(() => AUTH_VIEW.AUTH_SELECTION_VIEW)}
						registerFn={() => setCurrentView(() => AUTH_VIEW.REGISTER_VIEW)}
					/>
				</RenderIf>

				<RenderIf condition={authType === 'PHONE'}>
					<PhoneAuth
						loginSuccessFn={emitLoginAnalytics}
						changeTypeFn={() => setAuthType('EMAIL')}
						backFn={() => setCurrentView(() => AUTH_VIEW.AUTH_SELECTION_VIEW)}
						registerFn={() => setCurrentView(() => AUTH_VIEW.REGISTER_VIEW)}
					/>
				</RenderIf>
			</RenderIf>

			<RenderIf condition={currentView === AUTH_VIEW.PASSWORD_RECOVERY_VIEW}>
				<PasswordRecovery
					backFn={showLoginForm}
					recoverySuccessFn={showLoginForm}
				/>
			</RenderIf>

			<RenderIf condition={currentView === AUTH_VIEW.REGISTER_VIEW}>
				<RenderIf condition={process.env.REACT_APP_AUTH_TYPE === 'EMAIL'}>
					<RegularRegister
						backFn={() => setCurrentView(() => AUTH_VIEW.AUTH_SELECTION_VIEW)}
						registerSuccessFn={showLoginForm}
					/>
				</RenderIf>

				<RenderIf condition={process.env.REACT_APP_AUTH_TYPE === 'PHONE'}>
					<PhoneRegister
						backFn={() => setCurrentView(() => AUTH_VIEW.AUTH_SELECTION_VIEW)}
						registerSuccessFn={showLoginForm}
					/>
				</RenderIf>
			</RenderIf>
		</Modal>
	);
}

export default ModalAuth;
